import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import {Pageheight} from "../index";
import React from 'react';
import { FaFilePdf, FaCheck, FaEuroSign, FaShare, FaEdit} from 'react-icons/fa';
import GlobalCSS from "../App.css";
import useBackend, {CallAPI, Loading, SpinnerX, Check, Header} from '../services/useBackend';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          resultVariant :"success",
          resultVisible:false,
          resultText :'N/A',
          spinnerHidden:false,
          invoices:null,
          totalAmountUnpaid:0.00
        }
    }

    CheckAccessToken;

    handleSubmit = (e) =>{
        e.preventDefault();    
        this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Function not implemented yet!', spinnerHidden:true  });
    }     

    componentDidMount() {
        this.getData();
    }

    getData = () =>{
        fetch('/backend/invoices', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accessToken':localStorage.getItem('access_token'),
        },
        }).then(response => response.json())
        .then(data => {
            if (data.errorsException===false) {

                data.invoices.forEach(cloudService => {
                    //console.log(contacts.tag);
                });

                this.setState({ resultVariant :'success', resultVisible:false, resultText: data.resultText, spinnerHidden:true, invoices:data.invoices, totalAmountUnpaid:parseFloat(data.totalAmountUnpaid).toFixed(2) });
            } else {
                console.log("2");
                this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
            }
        })
        .catch(error => {
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
            //this.setState({   });
        });
    }  
       

    render() {
        if (this.state.invoices !== null) {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>                        
                        <Header Title="Invoicing">Total amount unpaid: {this.state.totalAmountUnpaid}</Header>
                        <StyckeCompact>
                            <Table  responsive="sm"  class="gridtable">
                                <thead>
                                    <tr>    <td>Identity</td><td className="d-lg-block d-none">Created</td><td>Customer</td><td className="d-lg-block d-none">Int. Comment</td><td>Unpaid</td> <td>Sent</td> <td>Payed</td> <td>Action</td>   </tr>
                                </thead>
                                <tbody>
                                    {this.state.invoices.map((item) => {
                                        return <tr>                                         
                                         <td><a href={'/invoicing/details/'+item.guid}>{item.identifier}</a></td> <td className="d-lg-block d-none">{item.created}</td> <td><small>{item.customer}</small></td>  <td className="d-lg-block d-none"><small>{item.internalComment}</small></td> <td>{item.amount}</td>
                                         <td><FaCheck hidden={!item.sent}/></td>
                                         <td><FaCheck hidden={!item.payed}/></td>
                                         <td>   <a href={'/backend/invoices/'+item.guid} target="_blank"><FaFilePdf /></a>&nbsp;&nbsp;
                                                <a href={'/invoicing/details/'+item.guid} ><FaEdit /></a>&nbsp;&nbsp;
                                         </td>
                                         </tr>
                                    })}
                                </tbody>
                            </Table>
                        </StyckeCompact>  

                        <Form onSubmit={this.handleSubmit}>

                            <Form.Group className="mb-3"  hidden={this.state.spinnerHidden}>
                                <SpinnerX  />
                            </Form.Group>                                      
                                
                            <Form.Group className="mb-3"  hidden={!this.state.resultVisible}>
                                <Alert show={this.state.resultVisible} variant={this.state.resultVariant}  className="FormSpace">{this.state.resultText}</Alert> 
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Button className="CloudButton" variant="primary" type="submit">New Invoice</Button>
                            </Form.Group>                                
                        </Form>                 
                    </Sida>
                    </Container>
                </ContentWrapper>
            );
        } else {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Loading Invoices</Rubrik>
                        <SpinnerX  />
                    </Sida>
                    </Container>
                </ContentWrapper>
            );        
        }
    }
}

export default MyComponent;
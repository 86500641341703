import { Container, Button,Form, Alert, Modal, Table} from 'react-bootstrap';

import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";
import {Pageheight} from "../index";
import React, {useMemo, useState, useEffect } from 'react';
import { FaFilePdf, FaCheck, FaEuroSign, FaShare, FaEdit} from 'react-icons/fa';
import useBackend, {CallAPI, Loading, SpinnerX, Check, Header} from '../services/useBackend';
import {SplitoutLocalDateFromISO} from '../services/misc';

export default function MyComponent() {

    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText,    setResultText]    = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);
    const [spinnerHidden, setSpinnerHidden] = useState(true);

    const [timeLogAddModalshow, setTimeLogAddModalshow] = useState(false);
    const [timeLogUpdateModalshow, setTimeLogUpdateModalshow] = useState(false);
    const [timeLogItemToEdit, setTimeLogItemToEdit] = useState({comment:''});

    const [selectedRows, setSelectedRows] = useState([]);
    
    const initTimeLogAddModal = () => { return setTimeLogAddModalshow(!timeLogAddModalshow) } 
    const initTimeLogUpdateModal = () => { return setTimeLogUpdateModalshow(!timeLogUpdateModalshow) } 
    
    const {loading:productGroupsLoading, data:ProductGroups, Refresh} =  useBackend(process.env.PUBLIC_URL+'/backend/timelog');

    async function AddClickHandler() {
        const ProductForm = document.getElementById('FrmTimeLogAdd');
        const formData = new FormData(ProductForm);

        const formDataObj = Object.fromEntries(formData.entries());

        setSpinnerHidden(false);
        let {resultVariant, resultVisible, resultText, errorsException} = await CallAPI("/backend/timelog/","POST", JSON.stringify(formDataObj));

        if (resultVariant!="success") {
            setResultVariant(resultVariant);   
            setResultText(resultText);
            setResultVisible(resultVisible);
        } else {
            initTimeLogAddModal();
        }        
        setSpinnerHidden(true);
        Refresh();

        //e.target.reset() ;      
    }

    async function UpdateClickHandler() {
        const ProductForm = document.getElementById('FrmTimeLogUpdate');
        const formData = new FormData(ProductForm);
        const formDataObj = Object.fromEntries(formData.entries());

        setSpinnerHidden(false);
        let {resultVariant, resultVisible, resultText, errorsException} = await CallAPI("/backend/timelog/"+timeLogItemToEdit.guid,"PATCH", JSON.stringify(formDataObj));

        if (resultVariant!="success") {
            setResultVariant(resultVariant);   
            setResultText(resultText);
            setResultVisible(resultVisible);            
        } else {
            setTimeLogUpdateModalshow(false);
        }        
        setSpinnerHidden(true);
        Refresh();
        //e.target.reset() ;      
    }    

    async function DeleteClickHandler(TimeLogGUID)  {  
        setSpinnerHidden(false);
        await CallAPI("/backend/timelog/"+TimeLogGUID,"DELETE")
        setSpinnerHidden(true);
        setTimeLogUpdateModalshow(false);
        Refresh();
    }   
    
    async function EditClickHandler(event)  {
        event.preventDefault();
        const TimeLogGUID = event.currentTarget.getAttribute("TimeLogGUID")

        let TimeLogItem = ProductGroups.timelog.find(TLI=>TLI.guid==TimeLogGUID);
        setTimeLogItemToEdit(TimeLogItem);
        console.log(JSON.stringify(timeLogItemToEdit));

        initTimeLogUpdateModal();
        Refresh();     
    }
    
    async function InvoiceTimelogItems(event)  {
        event.preventDefault();
    
        const BodyData =  {
            TimeLogItemsToInvoice :  selectedRows
        }

        setSpinnerHidden(false);
        let {resultVariant, resultVisible, resultText, errorsException} = await CallAPI("/backend/timelog/InvoiceTimelog","POST", JSON.stringify(BodyData));
    
        setResultVariant(resultVariant);  
        setResultText(resultText);
        setResultVisible(true);     
        setSpinnerHidden(true);
        Refresh();     
    }      
        
    if (!productGroupsLoading) {
        return (
            <ContentWrapper>
                <Container>
                <Sida style={{minHeight:Pageheight(400)}}>
                    <Header Title="Timelog">Total amount uninvoiced: {ProductGroups.timeLogTotalUnivoiced} <Button variant="danger" onClick={initTimeLogAddModal}>+</Button></Header>
                    <StyckeCompact>
                        <Table  responsive="sm"  class="gridtable">
                            <thead>
                                <tr>  <td></td>  <td>Date</td> <td>Contact</td> <td>Product</td> <td>Qty</td> <td>Price</td> <td>Text</td> </tr>
                            </thead>
                            <tbody>
                                {ProductGroups.timelog.map((item) => {
                                    return <tr> <td><Form.Check TimeLogGUID={item.guid} onClick={(e)=> {e.target.checked ? setSelectedRows([...selectedRows, {Guid:item.guid}] ) : setSelectedRows(selectedRows.filter(s => s.Guid !== item.guid)) }}></Form.Check></td>   <td>{SplitoutLocalDateFromISO(item.doneDate)}</td> <td>{item.contact}</td> <td>{item.product}</td> <td>{item.qty}</td> <td>{item.price}</td> <td>{item.comment}</td>  <td><FaEdit TimeLogGUID={item.guid} onClick={EditClickHandler} /></td> </tr>
                                })}
                                <tr></tr>
                            </tbody>
                        </Table>
                        <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert>
                        {/* <Alert variant='warning'>{ selectedRows.map((item) => {return item.Guid }) }</Alert> */}
                        <Button onClick={InvoiceTimelogItems}>Fakturera valda poster</Button>  
                    </StyckeCompact>

                    <Modal show={timeLogUpdateModalshow} onHide={initTimeLogUpdateModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Uppdatera Tid/matrial</Modal.Title>                            
                        </Modal.Header>                            
                        <Modal.Body>

                                <div style={{width:"90%"}}>
                                    <Form id="FrmTimeLogUpdate">                                    
                                        <Form.Group className="mb-3">
                                            <Form.Label>Comment</Form.Label>
                                            
                                            <Form.Control type="text" name ="Comment" aria-rowspan={4} placeholder="Comment" defaultValue={timeLogItemToEdit.comment} />
                                        </Form.Group>                                
                                    </Form> 
                                </div>

                            <Form.Group className="mb-3"  hidden={spinnerHidden}>
                                <SpinnerX  />
                            </Form.Group>                     

                            <Form.Group className="mb-3"  hidden={!resultVisible}>
                                <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                            </Form.Group>                               

                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="CloudButton" variant="primary" type="button" onClick={UpdateClickHandler}>Spara</Button>
                            <Button className="QmeButton" variant="primary" type="button"  TimeLogGUID={timeLogItemToEdit.guid} onClick={() => DeleteClickHandler(timeLogItemToEdit.guid)}>Radera</Button>
                            <Button variant="danger" onClick={initTimeLogUpdateModal}>Avbryt</Button>                            
                        </Modal.Footer>                            

                    </Modal>                    

                    <Modal show={timeLogAddModalshow} onHide={initTimeLogAddModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Registrera Tid/matrial</Modal.Title>                            
                        </Modal.Header>                            
                        <Modal.Body>

                                <div style={{width:"90%"}}>
                                    <Form id="FrmTimeLogAdd">

                                        <Form.Group className="mb-3">                                                                
                                            <Form.Label>Contact</Form.Label>
                                            <Form.Select name='ContactId'>
                                            <option value={0}>Select</option>
                                                <option value={1632}>Preisler Instrument AB</option>
                                                <option value={1443}>Krinova AB</option>
                                                <option value={1640}>EyeOptics ApS</option>
                                                <option value={1223}>Rosenqvists Food Technologies</option>
                                                <option value={1441}>Balk och Hål AB</option>
                                                <option value={1608}>Malmö Lastbilscentral (LBC)</option>
                                                <option value={1460}>Folktandvården Skåne AB</option>
                                                <option value={1626}>Atelje Lyktan AB</option>
                                                <option value={1538}>Coca-Cola Haninge</option>
                                                <option value={1473}>EcoGuard</option>
                                                </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3">

                                        <Form.Label>Product</Form.Label>
                                        <Form.Select name='ProductId'>
                                            <option value={0}>Select</option>
                                            <option value={1962}>Konsulttid DBA</option>
                                            <option value={971}>Konsulttid Utveckling</option>
                                            <option value={1952}>Konsulttid Diverse</option>
                                            <option value={2004}>Installation/Ominstallation dator</option>
                                            <option value={2041}>Konsulttid Möte</option>
                                            <option value={2042}>Konsulttid Akut/Jour</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>DoneDate</Form.Label>
                                            <Form.Control type="date" name="DoneDate" placeholder="utfört Datum" defaultValue={Date.now()} />
                                        </Form.Group>                                        

                                        <Form.Group className="mb-3">
                                            <Form.Label>Qty</Form.Label>
                                            <Form.Control type="text" name ="Qty" placeholder="Qty"  defaultValue={1}/>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control type="text" name ="Price" placeholder="Price"  defaultValue={1250}/>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Comment</Form.Label>
                                            <Form.Control type="text" name ="Comment" placeholder="Comment" />
                                        </Form.Group>                                
                                    </Form> 
                                </div>

                            <Form.Group className="mb-3"  hidden={spinnerHidden}>
                                <SpinnerX  />
                            </Form.Group>                      

                            <Form.Group className="mb-3"  hidden={!resultVisible}>
                                <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                            </Form.Group>                               

                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="CloudButton" variant="primary" type="button" onClick={AddClickHandler}>Spara</Button>
                            <Button className="QmeButton" variant="primary" type="button" onClick={AddClickHandler}>Spara</Button>
                            <Button variant="danger" onClick={initTimeLogAddModal}>Avbryt</Button>                            
                        </Modal.Footer>                            

                    </Modal>

                    </Sida>
                </Container>
            </ContentWrapper>    
        );        
    } else {
        return (<Loading />);        
    }
}